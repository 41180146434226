/**
*
* site custom css
*/
/* variables */
.rouble {
  font-family: 'rouble';
  font-weight: normal;
  font-style: normal;
}
html {
  position: relative;
  height: auto;
  width: 100%;
  min-width: 1280px;
  box-sizing: border-box;
  min-height: 100vh;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  display: flex;
  flex-direction: column;
  min-width: 1280px;
  font-family: "ubuntu", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  min-height: 100vh;
}
.main-content {
  flex-grow: 1;
}
section.std-section {
  position: relative;
  width: 100%;
  max-width: 1280px;
  min-width: 1280px;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
}
section.dark {
  background-color: #f4f4f4;
}
/**
* Основной контейнер
*/
.container {
  width: 100%;
  max-width: 1140px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
}
.container-small {
  max-width: 980px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
}
/**
* общие кастомные стили
*/
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
button {
  cursor: pointer;
}
address {
  font-style: normal;
}
/**
* правила для плагина magnific popup
*/
html.no-scroll {
  overflow-y: hidden;
  padding-right: 15px;
}
.mfp-content .mfp-close {
  opacity: 1;
}
.mfp-content .mfp-close .icon {
  width: 25px;
  height: 25px;
  fill: #1d8fe0;
}
.posr {
  position: relative;
}
/**
* footer webmotor
*/
.webmotor span {
  color: #000;
}
.webmotor a {
  color: #000;
  text-decoration: underline;
  transition: 0.15s color ease-in;
}
.page-head {
  margin-bottom: 20px;
}
/**
* Это основной стилевой файл
*/
/**
* header desktop
* -----------------------------------------------------------------------------
*/
.header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 666;
}
.metadata-warning {
  width: 100%;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  position: relative;
  padding: .75rem 1.75rem .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  border-radius: 7px;
  margin: auto;
  display: block;
}
.metadata-warning button {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  outline: none;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  position: absolute;
  background: transparent;
  right: 10px;
  top: 5px;
  padding: 0;
  margin: 0;
  border: none;
}
.metadata-warning button:hover {
  color: #000;
  text-decoration: none;
  opacity: .75;
}
.header-section {
  padding-top: 15px;
  padding-bottom: 15px;
}
.header__top {
  align-items: center;
  padding-bottom: 20px;
}
.header__bottom {
  align-items: center;
  padding-top: 10px;
}
.header__nav {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}
.header__nav a,
.header__nav a.header__nav_link {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  text-decoration: none;
  transition: color ease-in-out 0.1s;
}
.header__nav a:hover,
.header__nav a.header__nav_link:hover {
  color: #017bd0;
}
.header__callback_phone {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
.header__callback_phone a {
  color: #017bd0;
  text-decoration: none;
}
.header__callback_fire {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  color: #000;
  font-size: 13px;
}
.header__logo {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: flex-end;
  text-decoration: none;
}
.header__logo_text {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 55px;
  line-height: 45px;
  font-weight: 700;
  color: #017bd0;
  text-transform: uppercase;
  margin-right: 18px;
}
.header__logo_img {
  display: block;
  max-width: 100px;
}
.header__info_item {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  line-height: 1.4;
  color: #666666;
}
.header__search_form {
  position: relative;
  width: 100%;
}
.header__search_input {
  width: 100%;
  height: 40px;
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 14px;
  color: #666666;
  border: 1px solid #e1e1e1;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 12px;
  padding-right: 32px;
}
.header__search_input:focus {
  outline: none;
}
.header__search_input::placeholder {
  color: #666666;
}
.header__search_input::-webkit-input-placeholder {
  color: #666666;
}
.header__search_input::-moz-placeholder {
  color: #666666;
}
.header__search_input:-moz-placeholder {
  color: #666666;
}
.header__search_input:-ms-input-placeholder {
  color: #666666;
}
.header__search_submit {
  position: absolute;
  top: 1px;
  right: 0;
  width: 39px;
  height: 39px;
  background-color: transparent;
  border: none;
  padding: 0;
}
.header__search_submit:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('../img/icons/search.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.breadcrumb {
  list-style-type: none;
  padding-left: 0;
  padding-right: 0;
  padding-top: 35px;
  padding-bottom: 40px;
  margin: 0;
}
.breadcrumb a {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  text-transform: none;
  color: #0082dd;
  text-decoration: none;
  padding-right: 5px;
}
.breadcrumb a[href] span {
  text-decoration: none;
}
.breadcrumb a[href]:hover span {
  text-decoration: underline;
}
.breadcrumb a span {
  overflow: hidden;
}
.breadcrumb a[href]:hover {
  color: #373737;
}
.breadcrumb a::after {
  display: inline-block;
  content: '/';
  padding-left: 5px;
  text-decoration: none;
}
.breadcrumb li {
  float: left;
}
.breadcrumb li:last-child a::after {
  display: none;
}
.breadcrumb li.active a {
  color: #373737;
}
.breadcrumb li.active a span {
  text-decoration: none;
}
.search-form {
  width: 100%;
  margin-bottom: 40px;
}
.search-form-input-wrapper {
  position: relative;
  display: inline;
}
.search-form__label {
  display: block;
  margin-bottom: 15px;
}
.search-form__input {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  display: inline-block;
  width: 100%;
  height: 46px;
  font-size: 16px;
  padding-left: 12px;
  padding-right: 57px;
  border-width: 1px;
  border-color: #e4e4e4;
  border-style: solid;
}
.search-form__input:focus {
  outline: 0;
}
.search-form__button-submit {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 7px;
  width: 40px;
  height: 40px;
  padding: 0;
  border: 0;
  background-color: transparent;
}
.search-form__button-submit .icon {
  width: 40px;
  height: 40px;
  padding: 7px;
  fill: #e0e0e0;
}
.search-result-list {
  margin-bottom: 40px;
}
.search-result-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-bottom: 30px;
}
.search-result-item:last-child {
  margin-bottom: 0;
}
.search-item-icon-wrapper {
  width: 45px;
  min-width: 45px;
  margin-right: 10px;
}
.search-item-icon-inner-wrapper {
  width: 45px;
  height: 45px;
  background-color: #f4f4f4;
  padding: 12px;
}
.search-item-icon-inner-wrapper svg,
.search-item-icon__image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.search-item-head {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 5px;
}
.search-item-head:hover .search-item-head__text {
  color: #e54d30;
}
.search-item-head:visited .search-item-head__text {
  color: #022d43;
}
.search-item-head__text {
  color: #1b7eb0;
  text-decoration: underline;
  transition: 0.15s color ease-in;
}
.search-item-head__date {
  color: #666;
  padding-left: 5px;
  text-decoration: none;
}
.search-item__caption .highlight {
  background-color: rgba(57, 181, 73, 0.42);
  transition: 0.15s color ease-in;
}
.search-item__caption .highlight-separate {
  font-weight: bold;
}
.content-section table {
  width: 100% !important;
  border: none !important;
  border-collapse: collapse !important;
  table-layout: auto !important;
  overflow-x: auto !important;
}
.content-section table th {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #000 !important;
  text-align: center !important;
  border: 2px solid #e4e4e4 !important;
  background-color: #f4f4f4 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.content-section table td {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: center !important;
  border: 2px solid #e4e4e4 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  transition: background ease-in-out 0.1s !important;
}
.content-section table tbody tr:hover td {
  background-color: #f4f4f4 !important;
}
.content-section .content img {
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.content-section .content img,
.content-section .content figure img {
  border: 3px solid #e4e4e4;
}
.content-section .content figure figcaption {
  font-size: 14px;
  color: #777777;
}
.content-section .content figure[style="float:left"] {
  margin-left: 0;
}
.content-section .content figure[style="float:right"] {
  margin-right: 0;
}
.content__doc_filename,
.content .content__doc_filename {
  font-weight: 700;
  font-size: 18px;
}
.content__doc_filetype,
.content .content__doc_filetype {
  color: #777777;
}
.content__doc_download,
.content .content__doc_download {
  position: relative;
  display: inline-block;
  font-weight: 700;
  color: #017bd0;
  text-decoration: none;
  vertical-align: middle;
  padding-left: 30px;
}
.content__doc_download:before,
.content .content__doc_download:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 20px;
  height: 20px;
  background-image: url('../img/icons/download.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.content__doc_download:hover,
.content .content__doc_download:hover {
  color: #017bd0;
  text-decoration: underline;
}
.content__doc_item,
.content .content__doc_item {
  margin-bottom: 20px;
}
.content__doc_link,
.content .content__doc_link {
  font-size: 18px;
  color: #017bd0;
  text-decoration: underline;
}
.content__doc_link:hover,
.content .content__doc_link:hover {
  color: #017bd0;
  text-decoration: none;
}
.item-list-section {
  padding-top: 35px;
  padding-bottom: 55px;
}
.item-list__visual {
  position: relative;
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
  height: 370px;
  overflow: hidden;
}
.item-list__visual img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100%;
  max-width: 90%;
}
.item-list__info {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  max-width: 50%;
  flex-basis: 50%;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 27px;
}
.item-list__info h2,
.item-list__info .h2 {
  align-self: stretch;
}
.item-list__info_link {
  align-self: stretch;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #000;
}
.item-list__info_link:hover {
  text-decoration: underline;
}
.item-list__info_type {
  font-family: "ubuntu", Helvetica, Tahoma, Arial, sans-serif;
  align-self: stretch;
  font-size: 18px;
  line-height: 1;
  color: #000;
  text-decoration: none;
  margin-bottom: 10px;
  transition: color ease-in-out 0.1s;
  display: block;
}
.item-list__info_type:hover {
  text-decoration: underline;
}
.item-list__info_model {
  font-family: "ubuntu", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  transition: color ease-in-out 0.1s;
  display: block;
}
.item-list__info_tech {
  align-self: stretch;
  padding-top: 50px;
  padding-bottom: 20px;
  color: #777777;
}
.item-list__info_buy {
  width: 100%;
  margin-top: auto;
}
.item-list__wrapper {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  max-height: 370px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  -moz-box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  margin-bottom: 20px;
  transition: all ease-in-out 0.1s;
}
.item-list__wrapper.certified:before {
  content: '';
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 46px;
  background-image: url('../img/items/certified.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  z-index: 111;
}
.item-list__wrapper:hover {
  -webkit-box-shadow: 0 0 20px 0 rgba(156, 154, 156, 0.4);
  -moz-box-shadow: 0 0 20px 0 rgba(156, 154, 156, 0.4);
  box-shadow: 0 0 20px 0 rgba(156, 154, 156, 0.4);
}
.item-list__wrapper:hover .item-list__info_link,
.item-list__wrapper:hover .item-list__info_type,
.item-list__wrapper:hover .item-list__info_model {
  color: #0082dd;
}
.item-list__adds_caption {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 20px;
  color: #373737;
  margin-bottom: 16px;
}
.item-list__adds img {
  display: block;
  max-width: 100px;
  max-height: 100px;
}
.item-list__adds_wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  text-decoration: none;
  height: 175px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  -moz-box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  padding-top: 19px;
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
  transition: all ease-in-out 0.1s;
}
.item-list__adds_wrapper.old .item-list__adds_caption {
  color: #c8c8c8;
}
.item-list__adds_wrapper:hover {
  -webkit-box-shadow: 0 0 20px 0 rgba(156, 154, 156, 0.4);
  -moz-box-shadow: 0 0 20px 0 rgba(156, 154, 156, 0.4);
  box-shadow: 0 0 20px 0 rgba(156, 154, 156, 0.4);
}
.item-list__adds_wrapper:hover .item-list__info_type,
.item-list__adds_wrapper:hover .item-list__info_model {
  color: #0082dd;
}
.about-section {
  padding-top: 70px;
  padding-bottom: 80px;
}
.about-section p {
  max-width: 790px;
  font-size: 17px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.about__wrapper {
  padding-top: 20px;
  padding-bottom: 50px;
}
.about__item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 265px;
  text-align: center;
  border: 2px solid #0082dd;
  border-radius: 4px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}
.about__item_imgwrap {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 110px;
  padding-bottom: 20px;
}
.about__item_imgwrap img {
  display: block;
  width: auto;
  max-width: 100%;
}
.about__item_heading {
  font-size: 18px;
  text-transform: uppercase;
  padding-bottom: 10px;
}
.about__item_caption {
  align-self: stretch;
  line-height: 1.2;
}
.about__order {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about__order .btn {
  margin-right: 14px;
}
.about__order .btn:last-child {
  margin-right: 0;
}
.about__order span {
  margin-right: 14px;
}
.about__order a:not(.btn) {
  color: #0082dd;
  text-decoration: none;
}
.about__order a:not(.btn):hover {
  text-decoration: underline;
}
.partners-section {
  padding-top: 60px;
  padding-bottom: 60px;
}
.partners-section h2 {
  margin-bottom: 50px;
}
.partners__wrapper {
  position: relative;
  width: 100%;
}
.partners__slider {
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
}
.partners__item_link {
  display: block;
}
.partners__item_imgwrap {
  position: relative;
}
.partners__item_imgwrap img {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
}
.partners__prev,
.partners__next {
  position: absolute;
  width: 40px;
  height: 60px;
  cursor: pointer;
}
.partners__prev svg,
.partners__next svg {
  width: 40px;
  height: 60px;
}
.partners__prev {
  top: 50%;
  transform: translateY(-50%);
  left: -50px;
}
.partners__next {
  top: 50%;
  transform: translateY(-50%);
  right: -50px;
}
.partners__next svg {
  transform: rotate(180deg);
}
.partners-light-section > .container {
  padding-top: 90px;
  padding-bottom: 90px;
  border-top: 2px solid #e4e4e4;
}
.partners-light-section-mob {
  padding-top: 35px;
  padding-bottom: 40px;
}
.partners-light-section h2 {
  margin-bottom: 50px;
}
.partners-light__wrapper {
  position: relative;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}
.partners-light__slider {
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
}
.partners-light__item_link {
  display: block;
}
.partners-light__item_imgwrap {
  position: relative;
}
.partners-light__item_imgwrap img {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
}
.partners-light__prev,
.partners-light__next {
  position: absolute;
  width: 40px;
  height: 60px;
  cursor: pointer;
}
.partners-light__prev svg,
.partners-light__next svg {
  width: 40px;
  height: 60px;
}
.partners-light__prev {
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
}
.partners-light__next {
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
}
.partners-light__next svg {
  transform: rotate(180deg);
}
.products-slider-section > .container {
  padding-top: 90px;
  padding-bottom: 90px;
  border-top: 2px solid #e4e4e4;
}
.products-slider-section-mob {
  padding-top: 35px;
  padding-bottom: 40px;
}
.products-slider-section h2 {
  margin-bottom: 50px;
}
.products-slider__wrapper {
  position: relative;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
}
.products-slider__slider {
  margin-left: auto;
  margin-right: auto;
}
.products-slider__slider .slick-list {
  overflow-x: hidden;
  overflow-y: visible;
  padding-top: 10px;
  padding-bottom: 10px;
}
.products-slider__item_heading {
  min-height: 75px;
}
.products-slider__item_type {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #424141;
  margin-bottom: 5px;
  transition: color ease-in-out 0.1s;
}
.products-slider__item_model {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  transition: color ease-in-out 0.1s;
}
.products-slider__item_link {
  display: block;
  text-decoration: none;
  padding-top: 24px;
  padding-bottom: 14px;
  padding-left: 15px;
  padding-right: 5px;
  text-align: left;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  -moz-box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  margin-right: 10px;
  margin-left: 10px;
  transition: all ease-in-out 0.1s;
}
.products-slider__item_link:hover {
  -webkit-box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  -moz-box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
  box-shadow: 0 0 20px 0 rgba(156, 154, 156, 0.4);
}
.products-slider__item_link:hover .products-slider__item_heading .products-slider__item_type,
.products-slider__item_link:hover .products-slider__item_heading .products-slider__item_model {
  color: #1b7eb0;
}
.products-slider__item_imgwrap {
  position: relative;
  width: 100%;
  height: 160px;
  overflow: hidden;
}
.products-slider__item_imgwrap img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  max-width: 100%;
  max-height: 125%;
  margin-right: auto;
  margin-left: auto;
}
.products-slider__prev,
.products-slider__next {
  position: absolute;
  width: 40px;
  height: 60px;
  cursor: pointer;
}
.products-slider__prev svg,
.products-slider__next svg {
  width: 40px;
  height: 60px;
}
.products-slider__prev {
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
}
.products-slider__next {
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
}
.products-slider__next svg {
  transform: rotate(180deg);
}
.questions-section {
  padding-top: 70px;
  padding-bottom: 80px;
}
.questions-section p {
  max-width: 790px;
  font-size: 17px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
.questions__order {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}
.questions__order .btn {
  margin-right: 14px;
}
.questions__order .btn:last-child {
  margin-right: 0;
}
.questions__order span {
  margin-right: 14px;
}
.object__slider_main {
  position: relative;
}
.object__slider_main-prev,
.object__slider_main-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 43px;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}
.object__slider_main-prev svg,
.object__slider_main-next svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 43px;
  height: 50px;
}
.object__slider_main-prev {
  left: 0;
}
.object__slider_main-prev svg {
  left: 0;
}
.object__slider_main-next {
  right: 0;
}
.object__slider_main-next svg {
  right: 0;
  transform: translateY(-50%) rotateY(180deg);
}
.object__slider_main-wrapper {
  position: relative;
  border: 4px solid #e4e4e4;
  height: 400px;
}
.object__slider_main-item {
  position: relative;
  overflow: hidden;
  height: 392px;
}
.object__slider_main-item img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-height: 100%;
}
.object__slider_nav {
  padding-left: 30px;
  padding-right: 30px;
}
.object__slider_nav-prev,
.object__slider_nav-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 26px;
  height: 100%;
  cursor: pointer;
  z-index: 1;
}
.object__slider_nav-prev svg,
.object__slider_nav-next svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 23px;
  height: 30px;
  fill: #e4e4e4;
  transition: all ease-in-out 0.1s;
}
.object__slider_nav-prev:hover svg,
.object__slider_nav-next:hover svg {
  fill: #9e9a9a;
}
.object__slider_nav-prev {
  left: 0;
}
.object__slider_nav-prev svg {
  left: 0;
}
.object__slider_nav-next {
  right: 0;
}
.object__slider_nav-next svg {
  right: 0;
  transform: translateY(-50%) rotateY(180deg);
}
.object__slider_nav-wrapper {
  position: relative;
  padding-top: 5px;
  margin-left: -2.5px;
  margin-right: -2.5px;
}
.object__slider_nav-item {
  position: relative;
  height: 100px;
  overflow: hidden;
  border: 3px solid #e4e4e4;
  margin-left: 2.5px;
  margin-right: 2.5px;
  cursor: pointer;
  transition: border ease-in-out 0.1s;
}
.object__slider_nav-item.slick-current {
  border: 3px solid #e54d30;
}
.object__slider_nav-item:hover {
  border-color: #9e9a9a;
}
.object__slider_nav-item img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-height: 100%;
}
.object__top_heading {
  padding-bottom: 40px;
}
.object__top_heading-type {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 35px;
  font-weight: 400;
  line-height: 39px;
  display: block;
}
.object__top_heading-model {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 35px;
  font-weight: 700;
  display: block;
}
.object__top_misc {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  margin-bottom: 65px;
}
.object__top_certificate {
  position: relative;
  padding-left: 60px;
  margin-right: 20px;
}
.object__top_certificate:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 45px;
  height: 45px;
  background-image: url('../img/icons/cert.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.object__top_manual {
  position: relative;
  display: block;
  font-size: 14px;
  color: #1b7eb0;
  text-decoration: none;
  padding-left: 60px;
}
.object__top_manual:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 45px;
  height: 45px;
  background-image: url('../img/icons/pdf.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.object__top_feedback {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
}
.object__top_feedback a.btn {
  width: 290px;
  margin-bottom: 15px;
}
.object__info_tabs.tab {
  display: block;
  text-align: center;
  border-bottom: 2px solid #e4e4e4;
}
.object__info_tabs.tab li {
  display: inline-block;
  position: relative;
  top: 2px;
  flex-grow: unset;
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-weight: 400;
  padding-left: 25px;
  padding-right: 25px;
  z-index: 1;
}
.object__info_tabs.tab li a {
  color: #424141;
  background-color: transparent;
  border-bottom: 3px solid transparent;
  padding-top: 16px;
  padding-bottom: 16px;
  transition: all ease-in-out 0.1s;
}
.object__info_tabs.tab li:hover a {
  border-bottom: 3px solid #9e9a9a;
}
.object__info_tabs.tab li.active {
  font-weight: 700;
}
.object__info_tabs.tab li.active a {
  color: #424141;
  background-color: transparent;
  border-bottom: 3px solid #1d8fe0;
}
.object__info_content {
  padding-top: 40px;
  padding-bottom: 50px;
  margin-bottom: 0;
}
.footer-section {
  padding-top: 60px;
  padding-bottom: 40px;
}
.footer__top {
  align-items: center;
  padding-bottom: 20px;
}
.footer__bottom {
  padding-top: 10px;
}
.footer__nav {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}
.footer__nav a,
.footer__nav a.footer__nav_link {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  text-decoration: none;
  transition: color ease-in-out 0.1s;
}
.footer__nav a:hover,
.footer__nav a.footer__nav_link:hover {
  color: #017bd0;
}
.footer__callback_phone {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
.footer__callback_phone a {
  color: #017bd0;
  text-decoration: none;
}
.footer__callback_fire {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  color: #000;
  font-size: 13px;
}
.footer__logo {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: #017bd0;
  text-transform: uppercase;
  text-decoration: none;
}
.footer__info {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}
.footer__info_item {
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  line-height: 1.4;
  color: #666666;
}
.footer__search_form {
  position: relative;
  width: 100%;
}
.footer__search_input {
  width: 100%;
  height: 40px;
  font-family: "ptsans", Helvetica, Tahoma, Arial, sans-serif;
  font-size: 14px;
  color: #666666;
  border: 1px solid #e1e1e1;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 12px;
  padding-right: 32px;
}
.footer__search_input:focus {
  outline: none;
}
.footer__search_input::placeholder {
  color: #666666;
}
.footer__search_input::-webkit-input-placeholder {
  color: #666666;
}
.footer__search_input::-moz-placeholder {
  color: #666666;
}
.footer__search_input:-moz-placeholder {
  color: #666666;
}
.footer__search_input:-ms-input-placeholder {
  color: #666666;
}
.footer__search_submit {
  position: absolute;
  top: 1px;
  right: 0;
  width: 39px;
  height: 39px;
  background-color: transparent;
  border: none;
  padding: 0;
}
.footer__search_submit:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url('../img/icons/search.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.footer__moblink a {
  color: #777;
  font-size: 14px;
  position: relative;
  left: 5px;
}
.mfp-wrap {
  overflow-y: unset !important;
}
/**
*/
/**
* файл для стилей для разных устройств
*/
