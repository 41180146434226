/**
* header desktop
* -----------------------------------------------------------------------------
*/
.header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  z-index: 666;
}
.metadata-warning {
    width: 100%;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    position: relative;
    padding: .75rem 1.75rem .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    position: fixed;
    left: 50%;
    transform:translateX(-50%);
    bottom: 20px;
    border-radius: 7px;
    margin: auto;
    display: block;
    button {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      outline: none;
      color: #000;
      text-shadow: 0 1px 0 #fff;
      opacity: .5;
      position: absolute;
      background: transparent;
      right: 10px;
      top: 5px;
      padding: 0;
      margin: 0;
      border: none;
      &:hover {
        color: #000;
        text-decoration: none;
        opacity: .75;
      }
  }
}

// header start

.header {

  &-section {
    padding-top: 15px;
    padding-bottom: 15px;

  }

  &__top {
    align-items: center;
    padding-bottom: 20px;

  }
  &__bottom {
    align-items: center;
    padding-top: 10px;
  }

  &__nav {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;


    & a, & a.header__nav_link {
      font-family: @secondary-font;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      color: #000;
      text-decoration: none;

      transition: color ease-in-out 0.1s;

      &:hover {
        color: #017bd0;
      }

    }
  }

  &__callback {

    &_phone {
      font-family: @secondary-font;
      font-size: 18px;
      font-weight: 700;
      color: #000;
      
      & a {
        color: #017bd0;
        text-decoration: none;
      }
    }

    &_fire {
      font-family: @secondary-font;
      color: #000;
      font-size: 13px;
    }

  }

  &__logo {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: flex-end;
    
    text-decoration: none;

    &_text {
      font-family: @secondary-font;
      font-size: 55px;
      line-height: 45px;
      font-weight: 700;
      color: #017bd0;
      text-transform: uppercase;

      margin-right: 18px;
    }

    &_img {
      display: block;

      max-width: 100px;
    }
  }

  &__info {

    &_item {
      font-family: @secondary-font;
      line-height: 1.4;
      color: #666666;
    }
  }

  &__search {

    &_form {
      position: relative;

      width: 100%;
    }

    &_input {

      width: 100%;
      height: 40px;

      font-family: @secondary-font;
      font-size: 14px;

      color: #666666;

      border: 1px solid #e1e1e1;

      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 12px;
      padding-right: 32px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #666666;
      }
      &::-webkit-input-placeholder {
        color: #666666;
      }
      &::-moz-placeholder {
        color: #666666;
      }
      &:-moz-placeholder {
        color: #666666;
      }
      &:-ms-input-placeholder {
        color: #666666;
      }
    }

    &_submit {
      position: absolute;
      top: 1px;
      right: 0;

      width: 39px;
      height: 39px;

      background-color: transparent;
      border: none;

      padding: 0;

      &:before {
        content: '';
        
        display: inline-block;
        
        width: 20px;
        height: 20px;

        background-image: url('../img/icons/search.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }
    }

  }

}

// header stop

// breadcrumb start

.breadcrumb {
  @breadcrumb-color-normal:       #0082dd;
  @breadcrumb-color-hover:        #373737;
  @breadcrumb-text-transform:     none;
  @breadcrumb-font-weight:        400;
  @breadcrumb-font-size:          16px;
  @breadcrumb-line-height:        18px;
  @breadcrumb-padding-top:        35px;
  @breadcrumb-padding-bottom:     40px;
  @breadcrumb-icon-display:       inline-block; // "none" или "inline-block"
  @breadcrumb-separator:          '/';


  list-style-type: none;
  padding-left: 0;
  padding-right: 0;
  padding-top: @breadcrumb-padding-top;
  padding-bottom: @breadcrumb-padding-bottom;
  margin: 0;

  & a {
    font-size: @breadcrumb-font-size;
    line-height: @breadcrumb-line-height;
    font-weight: @breadcrumb-font-weight;
    text-transform: @breadcrumb-text-transform;
    color: @breadcrumb-color-normal;
    text-decoration: none;
    padding-right: 5px;

    &[href] {
      & span {
        text-decoration: none;
      }

      &:hover {

        & span {
          text-decoration: underline;
        }

      }
    }

    & span {
      overflow: hidden;
    }

    &[href]:hover {
      color: @breadcrumb-color-hover;
    }
    &::after {
      display: inline-block;
      content: @breadcrumb-separator;
      padding-left: 5px;
      text-decoration: none;
    }
  }
  & li {
    float: left;

    &:last-child a::after {
      display: none;
    }
    &.active a {
      color: @breadcrumb-color-hover;
      & span {
        text-decoration: none;
      }
    }
  }
}

// breadcrumb stop

// search start

.search-form {
  width: 100%;
  margin-bottom: 40px;
}

.search-form-input-wrapper {
  position: relative;
  display: inline;
}

.search-form__label {
  display: block;
  margin-bottom: 15px;
}

.search-form__input {
  font-family: @secondary-font;
  display: inline-block;
  width: 100%;
  height: 46px;
  font-size: 16px;
  padding-left: 12px;
  padding-right: 57px;
  border-width: 1px;
  border-color: #e4e4e4;
  border-style: solid;

  &:focus {
    outline: 0;
  }
}

.search-form__button-submit {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 7px;
  width: 40px;
  height: 40px;
  padding: 0;
  border: 0;
  background-color: transparent;

  & .icon {
    width: 40px;
    height: 40px;
    padding: 7px;
    fill: #e0e0e0;
  }
}

.search-result-list {
  margin-bottom: 40px;
}

.search-result-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.search-item-icon-wrapper {
  width: 45px;
  min-width: 45px; // special for ie
  margin-right: 10px;
}

.search-item-icon-inner-wrapper {
  width: 45px;
  height: 45px;
  background-color: #f4f4f4;
  padding: 12px;
}

.search-item-icon-inner-wrapper svg,
.search-item-icon__image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.search-item-head {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 5px;

  &:hover .search-item-head__text {
    color: @link-hover;
  }
  &:visited .search-item-head__text {
    color: @link-visited;
  }
}

.search-item-head__text {
  color: @link-color;
  text-decoration: underline;
  transition: 0.15s color ease-in;
}

.search-item-head__date {
  color: #666;
  padding-left: 5px;
  text-decoration: none;
}

.search-item__caption {
    & .highlight {
        background-color: rgba(57, 181, 73, 0.42);
        transition: 0.15s color ease-in;
    }

    & .highlight-separate {
        font-weight: bold;
    }
}

// search stop

// content start

.content-section {

  & table {

    width: 100% !important;
    
    border: none !important;
    border-collapse: collapse !important;

    table-layout: auto !important;

    overflow-x: auto !important;

    & th {

      font-family: @secondary-font !important;
      font-size: 14px !important;
      font-weight: 700 !important;

      color: #000 !important;
      text-align: center !important;

      border: 2px solid #e4e4e4 !important;
      background-color: #f4f4f4 !important;

      padding-left: 10px !important;
      padding-right: 10px !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;

    }

    & td {

      font-family: @secondary-font !important;
      font-size: 14px !important;
      font-weight: 400 !important;

      text-align: center !important;

      border: 2px solid #e4e4e4 !important;

      padding-left: 10px !important;
      padding-right: 10px !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;

      transition: background ease-in-out 0.1s !important;

    }

    & tbody {

      & tr {
        
        // cursor: pointer !important;

        &:hover {

          & td {

            background-color: #f4f4f4 !important;

          }

        }

      }

    }

  }

  & .content img {

    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  & .content img, & .content figure img {

    border: 3px solid #e4e4e4;

  }

  & .content figure figcaption {

    font-size: 14px;
    color: #777777;

  }

  & .content figure[style="float:left"] {

    margin-left: 0;

  }

  & .content figure[style="float:right"] {

    margin-right: 0;

  }

}

.content__doc, .content .content__doc {

  &_wrapper {

    

  }

  &_filename {

    font-weight: 700;
    font-size: 18px;

  }

  &_filetype {

    color: #777777;

  }

  &_download {
    
    position: relative;

    display: inline-block;
    
    font-weight: 700;
    color: #017bd0;
    text-decoration: none;
    vertical-align: middle;

    padding-left: 30px;

    &:before {

      content: '';

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;

      width: 20px;
      height: 20px;

      background-image: url('../img/icons/download.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

    }

    &:hover {

      color: #017bd0;
      text-decoration: underline;

    }

  }

  &_item {

    margin-bottom: 20px;

  }

  &_link {
    
    font-size: 18px;
    color: #017bd0;
    text-decoration: underline;

    &:hover {
      
      color: #017bd0;
      text-decoration: none;

    }

  }

}

// content stop

// item-list start

.item-list {

  &-section {

    padding-top: 35px;
    padding-bottom: 55px;

  }

  &__visual {

    position: relative;

    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
    height: 370px;

    overflow: hidden;

    & img {

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      
      display: block;

      width: 100%;
      max-width: 90%;

    }

  }

  &__info {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
    
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 27px;
    & h2, & .h2 {
      align-self: stretch;
    }

    &_link {

      align-self: stretch;

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;

      text-decoration: none;
      color: #000;

      &:hover {
        
        text-decoration: underline;

      }

    }

    &_type {
      font-family: @main-font;

      align-self: stretch;

      font-size: 18px;
      line-height: 1;
      color: #000;
      text-decoration: none;

      margin-bottom: 10px;

      transition: color ease-in-out 0.1s;

      display: block;

      &:hover {

        text-decoration: underline;

      }
    }

    &_model {
      font-family: @main-font;

      font-size: 16px;
      font-weight: 500;

      transition: color ease-in-out 0.1s;

      display: block;

    }

    &_tech {
      align-self: stretch;

      padding-top: 50px;
      padding-bottom: 20px;
      color: #777777;

    }

    &_buy {

      width: 100%;
      margin-top: auto;

    }

  }

  &__wrapper {

    position: relative;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;

    max-height: 370px;

    background-color: #fff;

    -webkit-box-shadow: 0 0 15px 0 rgba(156,154,156,0.2);
    -moz-box-shadow: 0 0 15px 0 rgba(156,154,156,0.2);
    box-shadow: 0 0 15px 0 rgba(156,154,156,0.2);

    margin-bottom: 20px;

    transition: all ease-in-out 0.1s;

    &.certified {

      &:before {

        content: '';

        position: absolute;
        top: 20px;
        left: 20px;

        width: 40px;
        height: 46px;

        background-image: url('../img/items/certified.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

        z-index: 111;

      }

    }

    &:hover {

      -webkit-box-shadow: 0 0 20px 0 rgba(156,154,156,0.4);
      -moz-box-shadow: 0 0 20px 0 rgba(156,154,156,0.4);
      box-shadow: 0 0 20px 0 rgba(156,154,156,0.4);

      & .item-list__info_link, & .item-list__info_type, .item-list__info_model {

        color: #0082dd;

      }
    }
  }

  &__adds {

    &_caption {

      font-family: @secondary-font;
      font-size: 20px;
      color: #373737;

      margin-bottom: 16px;

    }

    & img {

      display: block;

      max-width: 100px;
      max-height: 100px;

    }

    &_wrapper {

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      text-align: center;
      text-decoration: none;

      height: 175px;

      background-color: #fff;

      -webkit-box-shadow: 0 0 15px 0 rgba(156,154,156,0.2);
      -moz-box-shadow: 0 0 15px 0 rgba(156,154,156,0.2);
      box-shadow: 0 0 15px 0 rgba(156,154,156,0.2);

      padding-top: 19px;
      padding-bottom: 14px;
      padding-left: 20px;
      padding-right: 20px;

      margin-bottom: 20px;

      transition: all ease-in-out 0.1s;

      &.old {

        & .item-list__adds_caption {

          color: #c8c8c8;

        }

      }

      &:hover {

        -webkit-box-shadow: 0 0 20px 0 rgba(156,154,156,0.4);
        -moz-box-shadow: 0 0 20px 0 rgba(156,154,156,0.4);
        box-shadow: 0 0 20px 0 rgba(156,154,156,0.4);

        & .item-list__info_type, .item-list__info_model {

          color: #0082dd;

        }
      }
    }
  }

}

// item-list stop

// about start

.about {
  &-section {
    padding-top: 70px;
    padding-bottom: 80px;

    // @media (max-width: 767px) {

    //   padding-top: 50px;
    //   padding-bottom: 40px;

    //   margin-bottom: 5px;

    // }

    & p {

      max-width: 790px;

      font-size: 17px;
      text-align: center;

      margin-left: auto;
      margin-right: auto;

    }

  }

  &__wrapper {
    padding-top: 20px;
    padding-bottom: 50px;

    // @media (max-width: 767px) {
      
    //   display: none;

    // }
  }

  &__item {

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-height: 265px;

    text-align: center;

    border: 2px solid #0082dd;
    border-radius: 4px;

    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;

    // @media (max-width: 1023px) {
    //   margin-bottom: 20px;
    // }

    &_imgwrap {

      position: relative;

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100px;
      height: 110px;

      padding-bottom: 20px;

      & img {
        display: block;

        width: auto;
        max-width: 100%;
      }

    }

    &_heading {
      font-size: 18px;
      text-transform: uppercase;

      padding-bottom: 10px;
    }

    &_caption {
      align-self: stretch;

      line-height: 1.2;
    }
  }
  
  &__order {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;

    // @media (max-width: 767px) {
    //   display: none;
    // }

    & .btn {
      margin-right: 14px;

      &:last-child {
        margin-right: 0;
      }
    }

    & span {
      margin-right: 14px;
    }

    & a:not(.btn) {
      color: #0082dd;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

}

// about stop

// partners start

.partners {

  &-section {

    padding-top: 60px;
    padding-bottom: 60px;

    & h2 {
      margin-bottom: 50px;
    }
    
  }

  &__wrapper {
    position: relative;

    width: 100%;
  }

  &__slider {
    max-width: 920px;

    margin-left: auto;
    margin-right: auto;
  }

  &__item {

    &_link {
      display: block;
    }

    &_imgwrap {
      position: relative;
      & img {
        margin-right: auto;
        margin-left: auto;
        max-width: 100%;
      }
    }

  }

  &__prev, &__next {
    position: absolute;

    width: 40px;
    height: 60px;

    cursor: pointer;

    & svg {
      width: 40px;
      height: 60px;
    }
  }

  &__prev {

    top: 50%;
    transform: translateY(-50%);
    left: -50px;

  }
  &__next {

    top: 50%;
    transform: translateY(-50%);
    right: -50px;

    & svg {
      transform: rotate(180deg);
    }

  }

}

.partners-light {

  &-section {

    // @media (max-width: 768px) {

    //   display: none;

    // }

    & > .container {

      padding-top: 90px;
      padding-bottom: 90px;

      border-top: 2px solid #e4e4e4;

    }

    // @media (max-width: 767px) {
    //   display: none;
    // }

    &-mob {

      padding-top: 35px;
      padding-bottom: 40px;

      // @media (min-width: 768px) {
      //   display: none;
      // }
    }

    & h2 {
      margin-bottom: 50px;
    }
    
  }

  &__wrapper {
    position: relative;

    width: 100%;

    padding-left: 40px;
    padding-right: 40px;

  }

  &__slider {
    max-width: 920px;

    margin-left: auto;
    margin-right: auto;
  }

  &__item {

    &_link {
      display: block;
    }

    &_imgwrap {
      position: relative;
      & img {
        margin-right: auto;
        margin-left: auto;
        max-width: 100%;
        // @media (max-width: 1023px) {
        //   max-width: 90%;
        // }
      }
    }

  }

  &__prev, &__next {
    position: absolute;

    width: 40px;
    height: 60px;

    cursor: pointer;

    & svg {
      width: 40px;
      height: 60px;
    }
  }

  &__prev {

    top: 50%;
    transform: translateY(-50%);
    left: -20px;

    // @media (max-width: 1024px) {
      
    //   left: -10px;

    // }

  }
  &__next {

    top: 50%;
    transform: translateY(-50%);
    right: -20px;

    // @media (max-width: 1024px) {
      
    //   right: -10px;

    // }

    & svg {
      transform: rotate(180deg);
    }

  }

}

// partners stop

// products-slider start

.products-slider {

  &-section {

    // @media (max-width: 768px) {
      
    //   display: none;

    // }

    & > .container {

      padding-top: 90px;
      padding-bottom: 90px;

      border-top: 2px solid #e4e4e4;

    }

    // @media (max-width: 767px) {
    //   display: none;
    // }

    &-mob {

      padding-top: 35px;
      padding-bottom: 40px;

      // @media (min-width: 768px) {
      //   display: none;
      // }
    }

    & h2 {
      margin-bottom: 50px;
    }
    
  }

  &__wrapper {
    position: relative;

    width: 100%;
    
    padding-left: 40px;
    padding-right: 40px;

  }

  &__slider {

    margin-left: auto;
    margin-right: auto;

    & .slick-list {

      overflow-x: hidden;
      overflow-y: visible;

      padding-top: 10px;
      padding-bottom: 10px; 

    }

  }

  &__item {

    &_heading {
      
      min-height: 75px;

    }

    &_type {

      font-family: @secondary-font;
      font-size: 16px;
      font-weight: 400;
      color: @text-color;
      
      margin-bottom: 5px;

      transition: color ease-in-out 0.1s;

    }

    &_model {

      font-family: @secondary-font;
      font-size: 14px;
      font-weight: 700;
      color: #000;

      transition: color ease-in-out 0.1s;

    }

    &_link {
      display: block;
      text-decoration: none;

      padding-top: 24px;
      padding-bottom: 14px;
      padding-left: 15px;
      padding-right: 5px;

      text-align: left;

      border-radius: 5px;

      -webkit-box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
      -moz-box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
      box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
      box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
      margin-right: 10px;
      margin-left: 10px;

      transition: all ease-in-out 0.1s;

      &:hover {
        
        -webkit-box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
        -moz-box-shadow: 0 0 15px 0 rgba(156, 154, 156, 0.2);
        box-shadow: 0 0 20px 0 rgba(156, 154, 156, 0.4);

        & .products-slider__item_heading .products-slider__item_type, & .products-slider__item_heading .products-slider__item_model,{

          color: @link-color;

        }

      }
    }

    &_imgwrap {
      position: relative;
      
      width: 100%;
      height: 160px;

      overflow: hidden;

      & img {
        
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        display: block;
        
        max-width: 100%;
        max-height: 125%;

        margin-right: auto;
        margin-left: auto;
      }
    }

  }

  &__prev, &__next {
    position: absolute;

    width: 40px;
    height: 60px;

    cursor: pointer;

    & svg {
      width: 40px;
      height: 60px;
    }
  }

  &__prev {

    top: 50%;
    transform: translateY(-50%);
    left: -20px;

    // @media (max-width: 1024px) {

    //   left: -10px;

    // }

  }
  &__next {

    top: 50%;
    transform: translateY(-50%);
    right: -20px;

    // @media (max-width: 1024px) {

    //   right: -10px;

    // }

    & svg {
      transform: rotate(180deg);
    }

  }

}

// products-slider stop

// question start

.questions {
  
  &-section {
    padding-top: 70px;
    padding-bottom: 80px;

    // @media (max-width: 767px) {

    //   display: none;

    // }

    & p {

      max-width: 790px;

      font-size: 17px;
      text-align: center;

      margin-left: auto;
      margin-right: auto;

      margin-bottom: 50px;

    }

  }
  
  &__order {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;

    & .btn {
      margin-right: 14px;

      &:last-child {
        margin-right: 0;
      }
    }

    & span {
      margin-right: 14px;
    }
  }

}

// question stop

// object start

.object {

  &__slider {

    &_main {

      position: relative;

      &-prev, &-next {

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        
        width: 43px;

        height: 100%;

        cursor: pointer;

        z-index: 1;

        & svg {
          
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          
          display: block;
          width: 43px;
          height: 50px;

        }

      }

      &-prev {
        
        left: 0;

        & svg {

          left: 0;

        }

      }

      &-next {
        
        right: 0;

        & svg {

          right: 0;
          
          transform: translateY(-50%) rotateY(180deg);

        }

      }

      &-wrapper {

        position: relative;

        border: 4px solid #e4e4e4;

        height: 400px;

      }

      &-item {

        position: relative;

        overflow: hidden;

        height: 392px;

        & img {

          display: block;

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);

          max-height: 100%;

        }

      }

    }

    &_nav {

      padding-left: 30px;
      padding-right: 30px;

      &-prev, &-next {

        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        
        width: 26px;

        height: 100%;

        cursor: pointer;
        z-index: 1;

        & svg {
          
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          
          display: block;
          width: 23px;
          height: 30px;

          fill: #e4e4e4;

          transition: all ease-in-out 0.1s;

        }

        &:hover {

          & svg {

            fill: #9e9a9a;

          }

        }

      }

      &-prev {
        
        left: 0;

        & svg {

          left: 0;

        }

      }

      &-next {
        
        right: 0;

        & svg {

          right: 0;
          
          transform: translateY(-50%) rotateY(180deg);

        }

      }

      &-wrapper {

        position: relative;

        padding-top: 5px;

        margin-left: -2.5px;
        margin-right: -2.5px;

      }

      &-item {

        position: relative;
        height: 100px;

        overflow: hidden;

        border: 3px solid #e4e4e4;

        margin-left: 2.5px;  
        margin-right: 2.5px;

        cursor: pointer;

        transition: border ease-in-out 0.1s;
        &.slick-current {
          border: 3px solid #e54d30;
        }

        &:hover {
          
          border-color: #9e9a9a;

        }

        & img {

          display: block;

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);

          max-height: 100%;

        }

      }

    }

  }

  &__top {

    &_heading {

      padding-bottom: 40px;

      &-type {

        font-family: @secondary-font;
        font-size: 35px;
        font-weight: 400;
        line-height: 39px;
        display: block;

      }

      &-model {

        font-family: @secondary-font;
        font-size: 35px;
        font-weight: 700;
        display: block;

      }

    }

    &_misc {

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center;

      margin-bottom: 65px;

    }

    &_certificate {
      
      position: relative;

      padding-left: 60px;

      margin-right: 20px;

      &:before {

        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 45px;
        height: 45px;

        background-image: url('../img/icons/cert.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

      }

    }

    &_manual {

      position: relative;

      display: block;
      
      font-size: 14px;
      color: @link-color;
      text-decoration: none;

      padding-left: 60px;

      &:before {

        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 45px;
        height: 45px;

        background-image: url('../img/icons/pdf.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

      }

    }

    &_feedback {

      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;

      & a.btn {

        width: 290px;
        
        margin-bottom: 15px;

      }

    }

  }

  &__info {

    &_tabs.tab {

      // display: -webkit-flex;
      // display: -moz-flex;
      // display: -ms-flex;
      // display: -o-flex;
      // display: flex;
      // justify-content: center;
      display: block;
      text-align: center;

      border-bottom: 2px solid #e4e4e4;

      & li {

        display: inline-block;

        position: relative;
        top: 2px;

        flex-grow: unset;

        font-family: @secondary-font;
        font-weight: 400;

        padding-left: 25px;
        padding-right: 25px;

        z-index: 1;

        & a {
          
          color: @text-color;
          background-color: transparent;

          border-bottom: 3px solid transparent;
          
          padding-top: 16px;
          padding-bottom: 16px;

          transition: all ease-in-out 0.1s;

        }

        &:hover {

          & a {

            border-bottom: 3px solid #9e9a9a;

          }

        }

        &.active {

          font-weight: 700;

          & a {
            
            color: @text-color;
            background-color: transparent;

            border-bottom: 3px solid @primary-color;

          }

        }

      }

    }

    &_content {

      // min-height: 580px;

      padding-top: 40px;
      padding-bottom: 50px;

      margin-bottom: 0;

    }

  }

}

// object stop 

// footer start

.footer {

  &-section {
    padding-top: 60px;
    padding-bottom: 40px;

    // @media (max-width: 767px) {

    //   display: none;

    // }

  }

  &__top {
    align-items: center;
    padding-bottom: 20px;
  }
  &__bottom {
    padding-top: 10px;
  }

  &__nav {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;

    // @media (max-width: 1023px) {
    //   justify-content: flex-start;
    //   padding-bottom: 20px;
    // }

    & a, & a.footer__nav_link {
      font-family: @secondary-font;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      color: #000;
      text-decoration: none;

      transition: color ease-in-out 0.1s;

      &:hover {
        color: #017bd0;
      }

      // @media (max-width: 1023px) {
      //   margin-right: 15px;
      //   &:last-child {
      //     margin-right: 0;
      //   }
      // }
    }
  }

  &__callback {

    &_phone {
      font-family: @secondary-font;
      font-size: 18px;
      font-weight: 700;
      color: #000;
      
      & a {
        color: #017bd0;
        text-decoration: none;
      }
    }

    &_fire {
      font-family: @secondary-font;
      color: #000;
      font-size: 13px;
    }

  }

  &__logo {
    font-family: @secondary-font;
    font-size: 32px;
    font-weight: 700;
    color: #017bd0;
    text-transform: uppercase;
    text-decoration: none;
  }

  &__info {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;

    // @media (max-width: 1023px) {
    //   margin-bottom: 20px;
    // }

    &_item {
      font-family: @secondary-font;
      line-height: 1.4;
      color: #666666;
    }
  }

  &__search {

    &_form {
      position: relative;

      width: 100%;
    }

    &_input {

      width: 100%;
      height: 40px;

      font-family: @secondary-font;
      font-size: 14px;

      color: #666666;

      border: 1px solid #e1e1e1;

      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 12px;
      padding-right: 32px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #666666;
      }
      &::-webkit-input-placeholder {
        color: #666666;
      }
      &::-moz-placeholder {
        color: #666666;
      }
      &:-moz-placeholder {
        color: #666666;
      }
      &:-ms-input-placeholder {
        color: #666666;
      }
    }

    &_submit {
      position: absolute;
      top: 1px;
      right: 0;

      width: 39px;
      height: 39px;

      background-color: transparent;
      border: none;

      padding: 0;

      &:before {
        content: '';
        
        display: inline-block;
        
        width: 20px;
        height: 20px;

        background-image: url('../img/icons/search.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
      }
    }

  }

  &__moblink {
    
    & a {

      color: #777;
      font-size: 14px;
      position: relative;
      left: 5px;

    }

  }

}

// footer stop

// misc start (and never stop because of monkey-coding)

.mfp-wrap {

  // top: 50% !important;
  // transform: translateY(-200%) !important;
  overflow-y: unset !important;

}

/**
*/
