html {
  position: relative;
  height: auto;
  // height: 100%;
  width: 100%;
  
  min-width: 1280px;
  box-sizing: border-box;

  // maybe
  min-height: 100vh;
}

*,*:before,*:after {box-sizing: inherit;}

body {
  display: flex;
  flex-direction: column;

  min-width: 1280px;

  font-family: @main-font;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  // min-height: 100%;  

  // maybe
  min-height: 100vh; 

  // @media (max-width:  768px) {
    
  //   background-color: #f4f4f4;

  // }
}

.main-content {
  flex-grow: 1;
}

section {
  &.std-section {

    position: relative;
    
    width: 100%;
    max-width: 1280px;
    min-width: 1280px;

    background-color: #fff;

    margin-left: auto;
    margin-right: auto;
  }
  &.dark {
    background-color: #f4f4f4;
  }
}

/**
* Основной контейнер
*/
.container {
  width: 100%;
  max-width: 1140px;
  padding-left: @grid-gutter-width / 2;
  padding-right: @grid-gutter-width / 2;
  margin-left: auto;
  margin-right: auto;
}
.container-small {
  max-width: 980px;
  padding-left: @grid-gutter-width / 2;
  padding-right: @grid-gutter-width / 2;
  margin-left: auto;
  margin-right: auto;
}

/**
* общие кастомные стили
*/
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
button { cursor: pointer; }
address { font-style:normal; }
/**
* правила для плагина magnific popup
*/
html.no-scroll {
  overflow-y: hidden;
  padding-right: 15px;
}
.mfp-content {
  & .mfp-close {

    opacity: 1;

    & .icon {
      width: 25px;
      height: 25px;
      fill: @primary-color;
    }
  }
}


.posr {
  position: relative;
}

/**
* footer webmotor
*/
.webmotor {
  & span {
    color: #000;
  }
  & a {
    color: #000;
    text-decoration: underline;
    transition: 0.15s color ease-in;
  }
}

.page-head {
  margin-bottom: 20px;
}
